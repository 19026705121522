import * as React from 'react';

import { Title, BackToTopButton, OnlineWebinars } from '../../components';

import { Page } from '../../fragments';

import './index.css';

const OnlineEducationPage = () => {
  return (
    <Page
      title="KOKOLINGO - Online edukacije"
      description="Organiziramo webinare za roditelje i webinare za stručnjake, 
      kako biosvijestili javnost oko problematike govornih poremećaja 
      djecepredškolske dobi."
    >
      <div className="online-webinar-block">
        <div className="online-webinar-block__container">
          <Title variant="dark" className="no-margin">
            KOKOLINGO ONLINE EDUKACIJE
          </Title>
          <OnlineWebinars />
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};

export default OnlineEducationPage;
